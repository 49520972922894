import { IonList } from "@ionic/react";

import {
  Button,
  Card,
  CardTitle,
  ContentCol,
  Description,
  Grid,
  Item,
  RatingScore,
  RatingScoreColor,
  Row,
  ScoreCol,
} from "../RatingsPage.styles";

export interface RatingCardProps {
  title: string;
  description: React.ReactNode;
  buttonText: string;
  buttonOnClick: () => void;
  scoreValue: number | string;
  scoreColor: RatingScoreColor;
  testId: string;
  showScoreCol?: boolean;
}

export function RatingCard(props: RatingCardProps) {
  const {
    title,
    description,
    buttonText,
    buttonOnClick,
    scoreValue,
    scoreColor,
    testId,
    showScoreCol = true,
  } = props;

  return (
    <Card>
      <Grid>
        <Row>
          <ContentCol>
            <IonList lines="none">
              <Item>
                <CardTitle>{title}</CardTitle>
              </Item>
              <Item>
                <Description>{description}</Description>
              </Item>
              <Item>
                <Button
                  fill="outline"
                  shape="round"
                  color={scoreColor}
                  size="default"
                  data-testid={`view-${testId}-button`}
                  onClick={buttonOnClick}
                >
                  {buttonText}
                </Button>
              </Item>
            </IonList>
          </ContentCol>
          {showScoreCol && (
            <ScoreCol>
              <RatingScore
                color={scoreColor}
                data-testid={`${testId}-score`}
                length={scoreValue.toString().length}
              >
                <span>{scoreValue}</span>
              </RatingScore>
            </ScoreCol>
          )}
        </Row>
      </Grid>
    </Card>
  );
}
